import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';

const Services = () => {
    const [services, setServices] = useState([]);

    const fetchServices = async () => {
        try {
            const response = await fetch('/data'); // Update the URL as needed
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setServices(data.services); // Assuming the response contains an array of service objects
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <>
            <NavBar />
            <div id="services" className="bg-gray-900 py-12 text-white">
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-300 uppercase font-bold">Services</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-300'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-300">
                            We are deeply committed to the growth and success of our clients.
                        </h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            {services.length > 0 ? (
                                services.map((service) => (
                                    <div key={service.id} className="bg-gray-800 transition-all ease-in-out duration-400 overflow-hidden text-gray-300 hover:bg-blue-600 hover:text-white rounded-lg shadow-2xl p-3 group">
                                        <div className="m-2 text-justify text-sm">
                                            <img alt={service.name} className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={service.image} />
                                            <h2 className="font-semibold my-4 text-2xl text-center">{service.name}</h2>
                                            <p className="text-md font-medium">
                                                {service.description}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-300">No services available.</p>
                            )}
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Services;
