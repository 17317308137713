import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';

const AdminPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [services, setServices] = useState([]);
    const [newBlog, setNewBlog] = useState({ title: '', content: '' });
    const [newJob, setNewJob] = useState({ title: '', content: '' });
    const [newService, setNewService] = useState({ name: '', description: '', image: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('blogs');

    const fetchData = async () => {
        try {
            const response = await fetch('/data');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBlogs(data.blogs || []);
            setJobs(data.jobs || []);
            setServices(data.services || []);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const saveData = async (data) => {
        try {
            await fetch('/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const addBlog = () => {
        const newId = blogs.length ? blogs[blogs.length - 1].id + 1 : 1;
        const updatedBlogs = [...blogs, { id: newId, ...newBlog }];
        setBlogs(updatedBlogs);
        saveData({ blogs: updatedBlogs, jobs, services });
        setNewBlog({ title: '', content: '' });
    };

    const deleteBlog = (id) => {
        const updatedBlogs = blogs.filter(blog => blog.id !== id);
        setBlogs(updatedBlogs);
        saveData({ blogs: updatedBlogs, jobs, services });
    };

    const addJob = () => {
        const newId = jobs.length ? jobs[jobs.length - 1].id + 1 : 1;
        const updatedJobs = [...jobs, { id: newId, ...newJob }];
        setJobs(updatedJobs);
        saveData({ blogs, jobs: updatedJobs, services });
        setNewJob({ title: '', content: '' });
    };

    const deleteJob = (id) => {
        const updatedJobs = jobs.filter(job => job.id !== id);
        setJobs(updatedJobs);
        saveData({ blogs, jobs: updatedJobs, services });
    };

    const uploadImage = async (file) => {
        // Implement your image upload logic here
        // For example, upload to a cloud service and return the URL
        // This is just a placeholder implementation
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('/upload', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Image upload failed');
        }

        const data = await response.json();
        return data.url; // Assuming the response contains the uploaded image URL
    };

    const addService = async () => {
        try {
            const imageUrl = await uploadImage(newService.image); // Upload image and get URL
            const newId = services.length ? services[services.length - 1].id + 1 : 1;
            const updatedServices = [...services, { id: newId, ...newService, image: imageUrl }];
            setServices(updatedServices);
            saveData({ blogs, jobs, services: updatedServices });
            setNewService({ name: '', description: '', image: '' });
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const deleteService = (id) => {
        const updatedServices = services.filter(service => service.id !== id);
        setServices(updatedServices);
        saveData({ blogs, jobs, services: updatedServices });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <NavBar />
            <div className="container mx-auto p-5 mt-20">
                <h1 className="text-3xl font-bold mb-5 text-green-400">Admin Page</h1>

                {/* Tab Buttons */}
                <div className="mb-5">
                    <button
                        onClick={() => setActiveTab('blogs')}
                        className={`px-4 py-2 ${activeTab === 'blogs' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
                    >
                        Blogs
                    </button>
                    <button
                        onClick={() => setActiveTab('jobs')}
                        className={`px-4 py-2 ${activeTab === 'jobs' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
                    >
                        Jobs
                    </button>
                    <button
                        onClick={() => setActiveTab('services')}
                        className={`px-4 py-2 ${activeTab === 'services' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
                    >
                        Services
                    </button>
                </div>

                {/* Conditional Rendering Based on Active Tab */}
                {activeTab === 'blogs' && (
                    <>
                        <h2 className="text-2xl font-semibold mt-5 text-green-400">Add Blog</h2>
                        <input
                            type="text"
                            placeholder="Title"
                            className="border p-2 w-full mb-2"
                            value={newBlog.title}
                            onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })}
                        />
                        <textarea
                            placeholder="Content"
                            className="border p-2 w-full mb-2"
                            value={newBlog.content}
                            onChange={(e) => setNewBlog({ ...newBlog, content: e.target.value })}
                        />
                        <button
                            onClick={addBlog}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                        >
                            Add Blog
                        </button>

                        <h2 className="text-2xl font-semibold mt-5 text-green-400">Blogs</h2>
                        {blogs.map(blog => (
                            <div key={blog.id} className="border p-4 mb-2 rounded">
                                <h3 className="text-xl font-bold text-white">{blog.title}</h3>
                                <p className='text-white'>{blog.content}</p>
                                <button
                                    onClick={() => deleteBlog(blog.id)}
                                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </>
                )}

                {activeTab === 'jobs' && (
                    <>
                        <h2 className="text-2xl font-semibold mt-5 text-green-400">Add Job</h2>
                        <input
                            type="text"
                            placeholder="Title"
                            className="border p-2 w-full mb-2"
                            value={newJob.title}
                            onChange={(e) => setNewJob({ ...newJob, title: e.target.value })}
                        />
                        <textarea
                            placeholder="Content"
                            className="border p-2 w-full mb-2"
                            value={newJob.content}
                            onChange={(e) => setNewJob({ ...newJob, content: e.target.value })}
                        />
                        <button
                            onClick={addJob}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                        >
                            Add Job
                        </button>

                        <h2 className="text-2xl font-semibold mt-5 text-green-400">Jobs</h2>
                        {jobs.map(job => (
                            <div key={job.id} className="border p-4 mb-2 rounded">
                                <h3 className="text-xl font-bold text-white">{job.title}</h3>
                                <p className='text-white'>{job.content}</p>
                                <button
                                    onClick={() => deleteJob(job.id)}
                                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </>
                )}

                {activeTab === 'services' && (
                    <>
                        <h2 className="text-2xl font-semibold mt-5 text-green-400">Add Service</h2>
                        <input
                            type="text"
                            placeholder="Service Name"
                            className="border p-2 w-full mb-2"
                            value={newService.name}
                            onChange={(e) => setNewService({ ...newService, name: e.target.value })}
                        />
                        <textarea
                            placeholder="Description"
                            className="border p-2 w-full mb-2"
                            value={newService.description}
                            onChange={(e) => setNewService({ ...newService, description: e.target.value })}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            className="border p-2 w-full mb-2"
                            onChange={(e) => {
                                if (e.target.files[0]) {
                                    setNewService({ ...newService, image: e.target.files[0] });
                                }
                            }}
                        />
                        <button
                            onClick={addService}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                        >
                            Add Service
                        </button>

                        <h2 className="text-2xl font-semibold mt-5 text-green-400">Services</h2>
                        {services.map(service => (
                            <div key={service.id} className="border p-4 mb-2 rounded">
                                {service.image && <img src={service.image} alt={service.name} className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-[200px]" />}
                                <h3 className="text-xl font-bold text-white">{service.name}</h3>
                                <p className='text-white'>{service.description}</p>
                                <button
                                    onClick={() => deleteService(service.id)}
                                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

export default AdminPage;
