import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useAuth } from '../../Context/AuthContext';
const NavLinks = () => {
    const { user, logout } = useAuth(); // Get user and logout function from context

    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" smooth to="/#services">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" smooth to="/blogs">
                Blogs
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" smooth to="/jobs">
                Jobs
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" to="/">
                Portfolio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" to="/contact">
                Contact Us
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" to="/admin">
               Admin
            </HashLink>
            {user ? (
                <button
                    onClick={logout}
                    className="px-4 font-extrabold text-gray-300 hover:text-red-400 text-left"
                >
                    Logout
                </button>
            ) : (
                <HashLink className="px-4 font-extrabold text-gray-300 hover:text-blue-400" to="/login">
                    Login
                </HashLink>
            )}
        </>
    );
}

export default NavLinks;
