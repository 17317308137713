import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext'; // Adjust the path as necessary

const ProtectedRoute = ({ element }) => {
  const { user } = useAuth(); // Get the user from context

  return user ? element : <Navigate to="/login" />; // Redirect to login if not authenticated
};

export default ProtectedRoute;
