import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';

const Blog = () => {
    const [blogs, setBlogs] = useState([]);

    const fetchBlogs = async () => {
        try {
            const response = await fetch('/data'); // Update the URL as needed
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBlogs(data.blogs); // Assuming the response is an array of blog objects
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <>
            <NavBar />
            <div className="flex flex-col min-h-screen bg-gray-900 text-white mt-20">
                <main className="flex-grow p-4 mt-20">
                    {blogs.length > 0 ? (
                        blogs.map((blog) => (
                            <article key={blog.id} className="bg-gray-800 shadow-md rounded-lg p-6 mb-4">
                                <h2 className="text-xl font-semibold">{blog.title}</h2>
                                <p className="text-gray-300 mt-2">{blog.content}</p>
                            </article>
                        ))
                    ) : (
                        <p className="text-gray-300">No blogs available.</p>
                    )}
                 
                </main>
            </div>
            <Footer />
        </>
    );
}

export default Blog;
