import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';

const Job = () => {
    const [jobs, setjobs] = useState([]);

    const fetchjobs = async () => {
        try {
            const response = await fetch('/data'); // Update the URL as needed
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setjobs(data.jobs); // Assuming the response is an array of job objects
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    useEffect(() => {
        fetchjobs();
    }, []);

    return (
        <>
            <NavBar />
            <div className="flex flex-col min-h-screen bg-gray-900 text-white mt-20">
                <main className="flex-grow p-4 mt-20">
                    {jobs.length > 0 ? (
                        jobs.map((job) => (
                            <article key={job.id} className="bg-gray-800 shadow-md rounded-lg p-6 mb-4">
                                <h2 className="text-xl font-semibold">{job.title}</h2>
                                <p className="text-gray-300 mt-2">{job.content}</p>
                            </article>
                        ))
                    ) : (
                        <p className="text-gray-300">No Job available.</p>
                    )}
                 
                </main>
            </div>
            <Footer />
        </>
    );
}

export default Job;
