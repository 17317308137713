import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Blog from './pages/Blogs';
import Job from './pages/Jobs';

import { AuthProvider } from './Context/AuthContext';
import LoginPage from './components/Auth/Login';

import ProtectedRoute from './components/Auth/ProtectedRoute';

import AdminPage from './pages/Admin';
function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("WMK-Tech");

  return (
    <>
      <Router>
        <AuthProvider>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/jobs" element={<Job />} />
              <Route path="/get-demo" element={<DemoProduct />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute element={<AdminPage />} />
                }
              />
            </Routes>
          </ScrollToTop>
        </AuthProvider>
      </Router>
    </>
  );
}


export default App;
