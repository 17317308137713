import React, { useState } from 'react';
import { useAuth } from '../../Context/AuthContext'; // Import the Auth context
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    const [password, setPassword] = useState('');
    const { login } = useAuth(); // Use the login function from Auth context
    
  const handleSubmit = (e) => {
    e.preventDefault();
    if(email === 'Admin@admin.com' && password === 'admin'){
        login(email); // Call the login function
        navigate('/admin')
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold text-center mb-6">WMK-Tech</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <button 
            type="submit" 
            className="w-full py-2 rounded bg-indigo-600 hover:bg-indigo-500 text-white font-semibold"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
