import React from 'react';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className=" bg-gray-900 text-white">
            <section data-aos="fade-up">
                <div className="py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-300 uppercase font-bold">Our Clients</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-300'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-300">Some of our clients.</h2>
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-8">
                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={kws} alt="KWS" className="object-contain" />                           
                        </div>

                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={protergia} alt="Protergia" className="object-contain" />                            
                        </div> 

                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={geps} alt="GEPS" className="object-contain" />                            
                        </div>                                           
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Clients;
